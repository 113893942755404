import { useState, useEffect } from 'react';

const useGameState = (getAccessTokenSilently, gridSize, colors) => {
    const [grid, setGrid] = useState([]);
    const [userClicks, setUserClicks] = useState(0);
    const [showPopup, setShowPopup] = useState(false); // Assuming you start without any pop-up
    const [gameOver, setGameOver] = useState(false);
    const [isPracticeMode, setIsPracticeMode] = useState(false);
    const existingUser = localStorage.getItem("firstVisit");

    const generateRandomGrid = () => {
        return Array.from({ length: gridSize }, () =>
            Array.from({ length: gridSize }, () => colors[Math.floor(Math.random() * colors.length)])
        );
    };

    const loadGame = async () => {
        if (isPracticeMode || !existingUser) {
            setGrid(generateRandomGrid());
        } else {
            const token = await getAccessTokenSilently();
            const apiUrl = `${process.env.REACT_APP_API_ROOT_URL}:${process.env.REACT_APP_API_PORT}/api/gamestate`;
            const localDate = new Date().toISOString().slice(0, 10);
            const response = await fetch(apiUrl, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'X-Client-Date': localDate
                }
            });
            const data = await response.json();
            if (response.ok) {
                setGrid(data.grid);
                setUserClicks(data.userClicks); // Load user clicks from the database
            } else {
                console.log('Failed to load game state or no game available.');
            }
        }
    };

    useEffect(() => {
        loadGame();
    }, [isPracticeMode]); // Reload the game whenever practice mode changes

    const saveGame = async (gameState) => {
        if (!isPracticeMode) {
            const token = await getAccessTokenSilently();
            const apiUrl = `${process.env.REACT_APP_API_ROOT_URL}:${process.env.REACT_APP_API_PORT}/api/gamestate`;
            const localDate = new Date().toISOString().slice(0, 10);
            await fetch(apiUrl, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                    'X-Client-Date': localDate
                },
                body: JSON.stringify(gameState)
            });
        }
    };

    useEffect(() => {
        if (!isPracticeMode && grid.length > 0 && userClicks !== 0) {
            saveGame({ grid, userClicks, gameOver });
        }
    }, [grid, userClicks, gameOver]);

    return {
        grid, setGrid, userClicks, setUserClicks, showPopup, setShowPopup, gameOver, setGameOver, setIsPracticeMode, generateRandomGrid, isPracticeMode
    };
};

export default useGameState;
