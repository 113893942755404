import React from 'react';

function ColorButton({ color, onClick, isSelected }) {
  const style = {
    backgroundColor: color,
    border: isSelected ? '3px solid black' : '1px solid grey',
    padding: isSelected ? '0px' : '0px',
    cursor: 'pointer',
    margin: '5px',
    transition: 'all 0.3s ease',
  };

  return (
    <button style={style} onClick={() => onClick(color)}>
      {/* Optional: Any other content */}
    </button>
  );
}

export default ColorButton;
