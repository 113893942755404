import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import Controls from "./Controls";
import GameRow from "./GameRow";
import ClickCounter from "./ClickCounter";
import "../css/GameBoard.css";
import useGameState from "../hooks/useGameState";
import useColorChange from "../hooks/useColorChange";
import useWindowSize from "../hooks/useWindowSize";
import PracticeModeButton from "./PracticeModeButton";
import Joyride, { STATUS } from 'react-joyride';


const colors = [
  "#f47068",
  "#ffb3ae",
  "#fff4f1",
  "#1697a6",
  "#0e606b",
  "#ffc24b",
];
const gridSize = 10;

function GameBoard() {
  const [run, setRun] = useState(false);
  const [steps, setSteps] = useState([
    {
      target: 'body',
      content: 'The aim of the game is to make all the squares on the board the same color using as few moves as possible. This tutorial will show you how to do it!',
      placement: 'center',
      title: 'Welcome to Jackpot Jigsaw!'
    },
    {
      target: '.controls',
      content: 'Pick a color from here, and then click on a tile in the grid that has a different color. When you do this, all the tiles that are touching and have the same color will change to your new color too!',
      placement: 'bottom',
      title: 'Color Controls'
    },
    {
      target: '.swap-button',
      content: "Want to switch tiles? Turn on 'Swap Mode' with the 'Swap Mode ON/OFF' button and swap spots between two adjacent tiles. Pick a tile, then click a nearby one to swap. You can only swap once per color change!",
      placement: 'top',
      title: 'Swapping Titles'
    },
    {
      target: '.user-clicks',
      content: "Every time you change a tile's color, your flip count increases by 1. Swapping tiles doesn't count towards your goal. Remember, the aim is to make the whole board the same color using as few flips as possible.",
      placement: 'top',
      title: 'Flip Count'
    },
    {
      target: '.grid',
      content: "This is your grid. A new puzzle will appear daily at midnight. Click the grid now to try out the controls you've learned. When you're done, click the red flashing circle to return to the tutorial, or hit the 'Practice Mode On' button to jump right into the game.",
      placement: 'top',
      title: 'The Gameboard Grid'
    },
    {
      target: '.practice-mode-button',
      content: 'This button shows which game mode is active. Practice mode lets you play unlimited unranked games, while Competitive mode ranks your game and you only get one chance per day. Click the button to switch between modes.',
      placement: 'top',
      title: 'Game Modes'
    },
    {
      target: '.refresh-button',
      content: 'This button refreshes games in Practice Mode. Each click generates a new random game.',
      placement: 'top',
      title: 'Game Modes II'
    },
    {
      target: '.tutorial-button',
      content: 'This button shows this tutorial again.',
      placement: 'top',
      title: 'View Tutorial Again'
    },
    {
      target: 'body',
      content: 'Good luck and have fun! I recommend starting in Practice Mode',
      placement: 'center',
      title: 'End of Tutorial'
    }
  ]);

  useEffect(() => {
    if (!localStorage.getItem("firstVisit")) {
      setRun(true);  // Start the tutorial if it's the user's first visit
      localStorage.setItem("firstVisit", "visited"); // Set 'firstVisit' to avoid future triggers
    }
  }, []);

  const handleJoyrideCallback = (data) => {
    const { status } = data;
    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      setRun(false);
    }
  };

  const { width } = useWindowSize();
  const { getAccessTokenSilently } = useAuth0();
  const {
    grid,
    setGrid,
    userClicks,
    setUserClicks,
    showPopup,
    gameOver,
    setGameOver,
    setIsPracticeMode,
    generateRandomGrid,
    isPracticeMode,
  } = useGameState(getAccessTokenSilently, gridSize, colors);
  const { selectedColor, setSelectedColor, changeColor} = useColorChange(
    grid,
    setGrid,
    setUserClicks,
    gridSize,
    colors,
    setGameOver
  );

  const [isSwapMode, setIsSwapMode] = useState(false);
  const [swapStart, setSwapStart] = useState(null);
  const [hasSwapped, setHasSwapped] = useState(false);
  const [colorChangedAfterSwap, setColorChangedAfterSwap] = useState(false);
  const [showSwapWarning, setShowSwapWarning] = useState(false);
  const existingUser = localStorage.getItem("firstVisit");

  const togglePracticeMode = () => {
    setIsPracticeMode(!isPracticeMode);
    setUserClicks(0);
    setGameOver(false);
    setRun(false);
  };

  const refreshPuzzle = () => {
    setGrid(generateRandomGrid(gridSize, colors));
    setUserClicks(0);
    setGameOver(false);
  };

  useEffect(() => {
    if (!existingUser) {
        setIsPracticeMode(true);
        setUserClicks(0);
        setGameOver(false);
        const newGrid = generateRandomGrid(gridSize, colors);
        setGrid(newGrid);
    }
}, []);

  useEffect(() => {
    const checkGameOver = () => {
      if (grid.length === 0 || grid[0].length === 0) {
        return;
      }

      const firstColor = grid[0][0];
      const isGameOver = grid.every((row) =>
        row.every((color) => color === firstColor)
      );
      if (isGameOver) {
        setGameOver(true);
      }
    };

    checkGameOver();
  }, [grid]);

  const toggleSwapMode = () => {
    if (isSwapMode && swapStart) {
      setSwapStart(null);
    }
    setIsSwapMode(!isSwapMode);
  };

  const handleColorSelect = (color) => {
    setSelectedColor(color);
  };

  const handleCellClick = (x, y) => {
    if (isSwapMode) {
      if (hasSwapped && !colorChangedAfterSwap) {
        setShowSwapWarning(true);
        setTimeout(() => setShowSwapWarning(false), 3000);
        return;
      }
      if (swapStart) {
        if (swapStart.x !== x || swapStart.y !== y) {
          if (Math.abs(swapStart.x - x) + Math.abs(swapStart.y - y) === 1) {
            const swapSuccess = performSwap(swapStart, { x, y });
            setSwapStart(null);
            if (swapSuccess) {
              setHasSwapped(true);
              setColorChangedAfterSwap(false);
              setIsSwapMode(false);
            }
          }
        } else {
          setSwapStart(null);
        }
      } else {
        setSwapStart({ x, y });
      }
    } else {
      if (grid[x][y] !== selectedColor) {
        changeColor(x, y);
        setColorChangedAfterSwap(true);
        if (hasSwapped) {
          setHasSwapped(false);
        }
      }
    }
  };

  const performSwap = ({ x: x1, y: y1 }, { x: x2, y: y2 }) => {
    if (
      Math.abs(x1 - x2) + Math.abs(y1 - y2) === 1 &&
      grid[x1][y1] !== grid[x2][y2]
    ) {
      const newGrid = [...grid];
      [newGrid[x1][y1], newGrid[x2][y2]] = [grid[x2][y2], grid[x1][y1]];
      setGrid(newGrid);
      return true;
    }
    return false;
  };

  const today = new Date().toLocaleDateString("en-US");

  if (gameOver) {
    return <PracticeModeButton togglePracticeMode={togglePracticeMode} 
      isPracticeMode={isPracticeMode} refreshPuzzle={refreshPuzzle} userClicks={userClicks} gameOver={gameOver}/>;
  }

  return (
    <div>
      <Joyride
        continuous
        run={run}
        steps={steps}
        callback={handleJoyrideCallback}
        showProgress
        showSkipButton
        styles={{
          options: {
            zIndex: 10000,
          },
        }}
      />

      <PracticeModeButton togglePracticeMode={togglePracticeMode} 
        isPracticeMode={isPracticeMode} refreshPuzzle={refreshPuzzle} userClicks={userClicks} gameOver={gameOver}/>

      <div className="game-container">
        {showPopup ? <h3 className="header">Game for {today}</h3> : null}
        <div className="controls-container">
          <Controls
            colors={colors}
            changeColor={handleColorSelect}
            selectedColor={selectedColor}
            toggleSwapMode={toggleSwapMode}
            isSwapMode={isSwapMode}
          />
          <ClickCounter clicks={userClicks} />
          {showSwapWarning && (
            <div className="swap-warning">
              You must change a grid cell color before another swap.
            </div>
          )}
        </div>
        <div className="grid">
          {grid.length > 0 ? (
            grid.map((row, rowIndex) => (
              <GameRow
                key={rowIndex}
                row={row}
                rowIndex={rowIndex}
                onCellClick={handleCellClick}
                swapStart={swapStart}
              />
            ))
          ) : (
            <p>No game loaded for today. Check back tomorrow!</p>
          )}
        </div>
      </div>
    </div>
  );

}

export default GameBoard;
