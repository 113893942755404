import React from "react";
import logo from "../assets/logo.svg";


const Footer = () => (
  <footer className="bg-light p-3 text-center">
    <img className="mb-3" src={logo} alt="Jackpot Jigsaw Logo" width="40" />
    <p>
      Developed by <a href="https://www.juleson.us/" target="_blank" rel="noreferrer">Juleson</a>
      <br/> 
      STARTUP CYBERSECURITY EXPERTS 
      <br/>
    </p>
  </footer>
);

export default Footer;
