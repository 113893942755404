import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';

const LeaderboardNavigation = ({ onPrev, onNext }) => (
  <div className="leaderboard-nav">
    <button className="leaderboard-button" onClick={onPrev}>
      <FontAwesomeIcon icon={faArrowLeft} />
    </button>
    <button className="leaderboard-button" onClick={onNext}>
      <FontAwesomeIcon icon={faArrowRight} />
    </button>
  </div>
);

export default LeaderboardNavigation;
