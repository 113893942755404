import React from "react";

import logo from "../assets/logo.svg";

const Hero = () => (
  <div className="text-center hero my-5">
    <img className="mb-3 App-logo" src={logo} alt="Jackpot Jigsaw Logo" width="120" />
    <h1 className="mb-4">Welcome to Jackpot Jigsaw</h1>

    <p className="lead">
    The Ultimate Puzzle Arena: Compete to Claim the #1 Spot with Every Puzzle You Solve!
    </p>
  </div>
);

export default Hero;
