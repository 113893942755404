import React from 'react';

const LeaderboardList = ({ userEntry, otherEntries, onUserClick }) => (
  <ul className="leaderboard-list">
    {userEntry && (
      <li className="leaderboard-item top-leader">
        <span className="rank">{userEntry.rank}.</span>
        <span className="username">{userEntry.username}</span> -
        <span className="flips">{userEntry.flips} Flips</span>
      </li>
    )}
    {otherEntries.map((entry, index) => (
      <li key={index} className="leaderboard-item" onClick={() => onUserClick(entry.username)}>
        <span className="rank">{entry.rank}.</span>
        <span className="username">{entry.username}</span> -
        <span className="flips">{entry.flips} Flips</span>
      </li>
    ))}
  </ul>
);

export default LeaderboardList;
