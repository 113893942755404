import React from 'react';
import GameCell from './GameCell';

function GameRow({ row, rowIndex, onCellClick, swapStart }) {
  return (
    <div className="row">
      {row.map((color, colIndex) => (
        <GameCell
          key={`${rowIndex}-${colIndex}`}
          color={color}
          onClick={() => onCellClick(rowIndex, colIndex)}
          isHighlighted={swapStart && swapStart.x === rowIndex && swapStart.y === colIndex}
        />
      ))}
    </div>
  );
}

export default GameRow;
