import React, { createContext, useContext, useState, useEffect } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import { toast } from 'react-toastify';

export const UsernameContext = createContext();

export const UsernameProvider = ({ children }) => {
    const { user, getAccessTokenSilently } = useAuth0();
    const [username, setUsername] = useState('');

    useEffect(() => {
        const fetchUsername = async () => {
            if (!user) return;

            try {
                const token = await getAccessTokenSilently();
                const apiUrl = `${process.env.REACT_APP_API_ROOT_URL}:${process.env.REACT_APP_API_PORT}/api/username`;
                const response = await fetch(apiUrl, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });

                if (response.status === 200) {
                    const data = await response.json();
                    setUsername(data.username);
                } else {
                    toast.error('Failed to fetch username.');
                }
            } catch (error) {
                console.error('Error fetching username:', error);
                toast.error('Error fetching username.');
            }
        };

        fetchUsername();
    }, [user, getAccessTokenSilently]);

    return (
        <UsernameContext.Provider value={{ username, setUsername }}>
            {children}
        </UsernameContext.Provider>
    );
};

export const useUsername = () => useContext(UsernameContext);
