import { useState } from 'react';

const useColorChange = (grid, setGrid, setUserClicks, gridSize, colors, setGameOver) => {
    const [selectedColor, setSelectedColor] = useState(colors[0]);
    const [clickAnimation, setClickAnimation] = useState('');

    const changeColor = (x, y) => {
        if (grid[x][y] === selectedColor) return;
        floodFill(x, y, grid[x][y], selectedColor).then(() => {
            setUserClicks(prevClicks => prevClicks + 1);
            checkGameOver(); // Ensure this is called after the floodFill operation is complete
        });
    };
    
    const floodFill = async (x, y, targetColor, replacementColor) => {
        if (targetColor === replacementColor || grid[x][y] !== targetColor) return;
        let queue = [{x, y}];
        let newGrid = grid.map(row => [...row]);
    
        const delay = ms => new Promise(res => setTimeout(res, ms));
    
        while (queue.length > 0) {
            const { x, y } = queue.shift();
            if (newGrid[x][y] === targetColor) {
                newGrid[x][y] = replacementColor;
                setGrid(newGrid.map(row => [...row])); // Ensure React triggers a re-render
                await delay(10);  // Short delay to visualize the flood fill effect
    
                if (x > 0 && newGrid[x - 1][y] === targetColor) queue.push({ x: x - 1, y });
                if (x < gridSize - 1 && newGrid[x + 1][y] === targetColor) queue.push({ x: x + 1, y });
                if (y > 0 && newGrid[x][y - 1] === targetColor) queue.push({ x, y: y - 1 });
                if (y < gridSize - 1 && newGrid[x][y + 1] === targetColor) queue.push({ x, y: y + 1 });
            }
        }
    };
    
    const checkGameOver = () => {
        const firstColor = grid[0][0];
        const isGameOver = grid.every(row => row.every(color => color === firstColor));
        if (isGameOver) {
            setGameOver(true);
        }
    };
    
    return { selectedColor, setSelectedColor, changeColor, clickAnimation, setClickAnimation };
};

export default useColorChange;