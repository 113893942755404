import React, { Component } from "react";

import { Row, Col } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import contentData from "../utils/contentData";
import rulesData from "../utils/rulestData";

class Content extends Component {
  render() {
    return (
      <div className="next-steps my-5">
        <div className="next-steps my-5">
        Jackpot Jigsaw invites you to test your problem-solving skills in a unique daily challenge that could see you climb to the top of the leaderboard! Each day brings a new puzzle, a blend of intrigue and intellect, waiting to be solved. But that's not all – your quick wit and sharp solutions will help you improve your ranking and showcase your prowess as you solve puzzles more efficiently. Every correct placement of a puzzle piece brings you closer to mastering the game!
        </div>
        <hr></hr>
        <h2 className="my-5 text-center">About The Game</h2>
        <Row className="d-flex justify-content-between">
          {contentData.map((col, i) => (
            <Col key={i} md={5} className="mb-4">
              <h6 className="mb-3">
                <a href={col.link}>
                  <FontAwesomeIcon icon="star" className="mr-2" />
                  {col.title}
                </a>
              </h6>
              <p>{col.description}</p>
            </Col>
          ))}
        </Row>
        <hr></hr>
        <h2 className="my-5 text-center">Rules & Gameplay</h2>
        <Row className="d-flex justify-content-between">
          {rulesData.map((col, i) => (
            <Col key={i} md={5} className="mb-4">
              <h6 className="mb-3">
                <a href={col.link}>
                  <FontAwesomeIcon icon="star" className="mr-2" />
                  {col.title}
                </a>
              </h6>
              <p>{col.description}</p>
            </Col>
          ))}
        </Row>
      </div>
    );
  }
}

export default Content;
