import React, { useState, useEffect } from 'react';
import { withAuthenticationRequired, useAuth0 } from '@auth0/auth0-react';
import LeaderboardNavigation from '../components/LeaderboardNavigation';
import LeaderboardList from '../components/LeaderboardList';
import StatisticsModal from '../components/StatisticsModal';
import Loading from "../components/Loading";
import { useUsername } from '../contexts/UsernameContext';
import '../css/Leaderboard.css';

export const Leaderboard = () => {
    const { getAccessTokenSilently } = useAuth0();
    const [leaderboardData, setLeaderboardData] = useState([]);
    const [viewDate, setViewDate] = useState(new Date().toISOString().slice(0, 10));
    const today = new Date().toISOString().slice(0, 10);
    const { username } = useUsername();
    const [isModalOpen, setModalOpen] = useState(false);
    const [userStats, setUserStats] = useState({});
    const [clickedUsername, setClickedUsername] = useState('');

    useEffect(() => {
        const fetchLeaderboard = async () => {
            const token = await getAccessTokenSilently();
            const apiUrl = `${process.env.REACT_APP_API_ROOT_URL}:${process.env.REACT_APP_API_PORT}/api/leaderboard`;

            const response = await fetch(`${apiUrl}?date=${viewDate}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'X-Client-Date': viewDate
                },
            });

            if (response.status === 200) {
                const data = await response.json();
                setLeaderboardData(data.slice(0, 50)); // Only take top 50 entries
            } else {
                console.error('Failed to fetch leaderboard');
            }
        };

        fetchLeaderboard();
    }, [getAccessTokenSilently, viewDate]);

    const fetchUserId = async (username) => {
        const token = await getAccessTokenSilently();
        const apiUrl = `${process.env.REACT_APP_API_ROOT_URL}:${process.env.REACT_APP_API_PORT}/api/user-id?username=${username}`;

        const response = await fetch(apiUrl, {
            headers: {
                Authorization: `Bearer ${token}`
            },
        });

        if (response.status === 200) {
            const { userId } = await response.json();
            fetchUserStats(userId);
        } else {
            console.error('Failed to fetch userId');
        }
    };

    const fetchUserStats = async (userId) => {
        const token = await getAccessTokenSilently();
        const apiUrl = `${process.env.REACT_APP_API_ROOT_URL}:${process.env.REACT_APP_API_PORT}/api/statistics?userId=${userId}`;

        const response = await fetch(apiUrl, {
            headers: {
                Authorization: `Bearer ${token}`
            },
        });

        if (response.status === 200) {
            const stats = await response.json();
            setUserStats(stats);
            setModalOpen(true);
        } else {
            console.error('Failed to fetch user statistics');
        }
    };

    const closeModal = () => {
        setModalOpen(false);
    };

    const handleUsernameClick = (username) => {
        setClickedUsername(username);
        fetchUserId(username);
    };

    const changeDate = (days) => {
        const currentDate = new Date(viewDate);
        currentDate.setDate(currentDate.getDate() + days);
        const newDate = currentDate.toISOString().slice(0, 10);
        if (newDate > today) return; // Prevent future dates
        setViewDate(newDate);
    };

    const getTitle = () => {
        return viewDate === today ? "Today's Leaderboard" : `Leaderboard for ${viewDate}`;
    };

    const userEntry = leaderboardData.find(entry => entry.username === username);
    const otherEntries = leaderboardData.filter(entry => entry.username !== username);

    return (
        <div className="leaderboard-container">
            <h2 className="leaderboard-title">{getTitle()}</h2>
            <LeaderboardNavigation onPrev={() => changeDate(-1)} onNext={() => changeDate(1)} />
            <br />
            {leaderboardData.length > 0 ? (
                <LeaderboardList
                    userEntry={userEntry}
                    otherEntries={otherEntries}
                    onUserClick={handleUsernameClick}
                />
            ) : (
                <p className="text-center">No games completed yet...</p>
            )}
            <StatisticsModal
                isOpen={isModalOpen}
                onRequestClose={closeModal}
                userStats={userStats}
                username={clickedUsername}
            />
        </div>
    );
};

export default withAuthenticationRequired(Leaderboard, {
  onRedirecting: () => <Loading />,
});