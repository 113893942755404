import { useAuth0 } from "@auth0/auth0-react";
import React from "react";

export const LoginButton = () => {
  const { loginWithRedirect } = useAuth0();

  const handleLogin = async () => {
    await loginWithRedirect({
      appState: {
        returnTo: "/game",
      },
    });
  };

  return (
    <button
        id="qsLoginBtn"
        color="primary"
        block
        onClick={handleLogin}
    >
        Log in
  </button>
  );
};

export default LoginButton;

