import React, { useEffect, useState } from 'react';

function ClickCounter({ clicks }) {
  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    setAnimate(true);
    setTimeout(() => setAnimate(false), 1000); // Resets the animation state after 1 second
  }, [clicks]); // Reacts to changes in clicks

  return (
    <div
      className="user-clicks"
      style={{ animation: animate ? 'flipAnimation 1s forwards' : 'none' }}
    >
      {clicks} Flips
    </div>
  );
}

export default ClickCounter;
