import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "reactstrap";
import Today from "../components/Today";
import Loading from "../components/Loading";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import GameBoard from "../components/GameBoard";

export const Game = () => {
  const { isLoading } = useAuth0();

  useEffect(() => {
    const firstVisit = localStorage.getItem("firstVisit");
    if (!firstVisit) {
      localStorage.setItem("firstVisit", "visited");
    }
  }, []);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Container className="mb-5">
      <Row className="justify-content-center">
        <Col md={8} className="text-center">
          <h3>Jackpot Jigsaw Game for {Today}</h3>  
        </Col>
        <Col md={8} className="text-center">
            <GameBoard/>
        </Col>
      </Row>
    </Container>
  );
};

export default withAuthenticationRequired(Game, {
  onRedirecting: () => <Loading />,
});
