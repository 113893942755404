const rulesData = [
  {
    title: "Color Change Mode (Default)",
    description: "Simply click on tiles to change their colors to match an adjacent tile or complete a pattern."
  },
  {
    title: "Swap Mode",
    description: "Want to swap tiles? Toggle this mode on with the 'Swap Mode ON/OFF' button and switch places between two adjacent tiles."
  },
  {
    title: "Changing Colors",
    description: "Choose a new color from the control panel and click on any tile that isn't already that color to change it."
  },
  {
    title: "Swapping Tiles",
    description: "In Swap Mode, select a tile, then click an adjacent one to swap their positions. Remember, you can only swap once per color change to keep the game moving forward."
  },
  {
    title: "Count Your Flips",
    description: "Each move you make, whether changing a color or swapping tiles, counts as a flip. We keep track of all your flips, shown as your 'Flip' count."
  },
  {
    title: "Aim for Efficiency",
    description: "The goal is to solve the puzzle in as few flips as possible to show off your puzzle-solving prowess."
  },
  {
    title: "Completion",
    description: "Solve the puzzle by arranging all tiles in the correct pattern to win the game."
  },
  {
    title: "New Puzzle, New Challenge",
    description: "The game board resets every day with a new puzzle, giving you a fresh start and new challenges daily. Yesterday’s puzzle and interactions won’t carry over, so each day is a clean slate."
  }
];
  
  export default rulesData;
  