import React from 'react';
import ColorButton from './ColorButton';
import { useState, useEffect } from 'react';


function useWindowSize() {
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight
      });
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowSize;
}

function Controls({ colors, changeColor, selectedColor, toggleSwapMode, isSwapMode }) {
  const { width } = useWindowSize();
  
  const buttonStyle = {
    backgroundColor: isSwapMode ? 'green' : 'gray',
    color: 'white',
    border: '1px solid black',
    padding: width < 600 ? '6px 12px' : width < 1024 ? '7px 14px' : '8px 16px',
    cursor: 'pointer',
    width: '100%',
    boxSizing: 'border-box',
    marginTop: '10px',
    fontSize: width < 600 ? '12px' : width < 1024 ? '14px' : '16px'
  };

  return (
    <div className="controls">
      <div className="color-buttons">
        {colors.map(color => (
          <ColorButton
            key={color}
            color={color}
            onClick={() => changeColor(color)}
            isSelected={color === selectedColor}
          />
        ))}
      </div>
      <div className="swap-button">
        <button onClick={toggleSwapMode} style={buttonStyle}>
          {isSwapMode ? 'Swap Mode ON' : 'Swap Mode OFF'}
        </button>
      </div>
    </div>
  );
}

export default Controls;
