import React, { useState, useEffect } from 'react';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import Loading from "../components/Loading";
import { motion } from 'framer-motion';
import '../css/Statistics.css';

export const Statistics = () => {
    const { getAccessTokenSilently } = useAuth0();
    const [stats, setStats] = useState({
        topTen: 0,
        firstPlace: 0,
        percentile: 0,
        averageFlips: 0,
        minFlips: 0,
        maxFlips: 0
    });

    useEffect(() => {
        const fetchData = async () => {
            const token = await getAccessTokenSilently();
            const apiUrl = `${process.env.REACT_APP_API_ROOT_URL}:${process.env.REACT_APP_API_PORT}/api/statistics`;

            const statsResponse = await fetch(apiUrl, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            if (statsResponse.status === 200) {
                const data = await statsResponse.json();
                setStats(data);
            } else {
                console.error('Failed to fetch statistics');
            }
        };

        fetchData();
    }, [getAccessTokenSilently]);

    return (
        <div className="statistics-container" style={{ padding: "20px", maxWidth: "600px", margin: "0 auto" }}>
            <h1 style={{ textAlign: "center", marginBottom: "20px" }}>Game Statistics</h1>
            <motion.table initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 0.5 }} style={{ width: '100%', textAlign: 'center' }}>
                <thead>
                    <tr>
                        <th>Stat</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                <tr>
                        <td>1st Place Finishes</td>
                        <td>{stats.firstPlace}</td>
                    </tr>
                    <tr>
                        <td>Top 10 Finishes</td>
                        <td>{stats.topTen}</td>
                    </tr>
                    
                    <tr>
                        <td>Percentile Rank</td>
                        <td>{stats.percentile}%</td>
                    </tr>
                    <tr>
                        <td>Average Number of Flips</td>
                        <td>{stats.averageFlips}</td>
                    </tr>
                    <tr>
                        <td>Lowest Flips</td>
                        <td>{stats.minFlips}</td>
                    </tr>
                    <tr>
                        <td>Highest Flips</td>
                        <td>{stats.maxFlips}</td>
                    </tr>
                </tbody>
            </motion.table>
        </div>
    );
};

export default withAuthenticationRequired(Statistics, {
  onRedirecting: () => <Loading />,
});
