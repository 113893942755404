import React from 'react';
import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

const StatisticsModal = ({ isOpen, onRequestClose, userStats, username }) => (
  <Modal
    isOpen={isOpen}
    onRequestClose={onRequestClose}
    style={{
      overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.75)'
      },
      content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '90%',
        maxWidth: '600px'
      }
    }}
  >
    <button onClick={onRequestClose} style={{ position: 'absolute', top: '10px', right: '10px', color: 'red'}}>
      <FontAwesomeIcon icon={faTimes} />
    </button>
    <h2>{username} Statistics</h2>
    <div>
      <p>1st Place Finishes: {userStats.firstPlace}</p>
      <p>Top 10 Finishes: {userStats.topTen}</p>
      <p>Percentile Rank: {userStats.percentile}%</p>
      <p>Average Number of Flips: {userStats.averageFlips}</p>
      <p>Lowest Flips: {userStats.minFlips}</p>
      <p>Highest Flips: {userStats.maxFlips}</p>
    </div>
  </Modal>
);

export default StatisticsModal;
