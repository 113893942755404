import React from 'react';

function GameCell({ color, onClick, isHighlighted, size }) {
  const cellStyle = {
    backgroundColor: color,
    cursor: 'pointer',
    border: isHighlighted ? '2px solid green' : '2px solid #eee',
    width: `${size}px`,   // Dynamic sizing based on prop
    height: `${size}px`,  // Dynamic sizing based on prop
    borderRadius: '5px',
    transition: 'background-color 0.5s ease',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  };

  return (
    <div className="cell" style={cellStyle} onClick={onClick}>
      {/* Cell content if any */}
    </div>
  );
}

export default GameCell;
