import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch, faHandsHelping } from "@fortawesome/free-solid-svg-icons";
import '../css/PracticeModeButton.css'

const handleTutorial = () => {
  localStorage.removeItem('firstVisit');
  window.location.reload();
};

export const PracticeModeButton = ({togglePracticeMode, isPracticeMode, refreshPuzzle, userClicks, gameOver}) => {
    if (gameOver){
        return (
            <div>
              <button
                onClick={togglePracticeMode}
                className={`practice-mode-button ${isPracticeMode ? "practice-mode-on" : "practice-mode-off"}`}
              >
                {isPracticeMode ? "Practice Mode ON" : "Start Practicing Now"}
              </button>
              <div className="game-over">
                {isPracticeMode ? (
                  <div>
                    <h1>Congratulations, you completed a practice game in {userClicks} flips! Play again or switch to competitive mode.</h1>
                    <button
                      onClick={refreshPuzzle}
                      className="practice-mode-button refresh-button"
                      style={{ marginLeft: "10px" }}
                    >
                      <FontAwesomeIcon icon={faCircleNotch} title="Refresh" /> Play Again
                    </button>
                   
                  </div>
                ) : (
                  <h1>Congratulations, you completed today's game in {userClicks} flips!</h1>
                )}
              </div>
            </div>
          );    
    }

    return(
        <div>
        <button
        onClick={togglePracticeMode}
        className={`practice-mode-button ${
          isPracticeMode ? "practice-mode-on" : "practice-mode-off"
        }`}
      >
        {isPracticeMode ? "Practice Mode ON" : "Competitive Mode ON"}
      </button>
      {isPracticeMode && (
        <button
          onClick={refreshPuzzle}
          className="practice-mode-button refresh-button"
          style={{ marginLeft: "10px" }}
        >
          <FontAwesomeIcon icon={faCircleNotch} title="refresh" />
        </button>
      )}
        {isPracticeMode && (
        <button
        onClick={handleTutorial}
        className="practice-mode-button tutorial-button"
        style={{ marginLeft: "10px" }}
      >
        <FontAwesomeIcon icon={faHandsHelping} title="Tutorial" />
      </button>
      )}
      </div>
    );
};

export default PracticeModeButton;