const contentData = [
  {
    title: "Login & Solve",
    description: "Play every day to find a fresh jigsaw puzzle awaiting your intellect. Each puzzle is uniquely designed to test your problem-solving skills and refreshes every 24 hours."
  },
  {
    title: "Master The Moves",
    description: "Each move counts! Solve the puzzle in the fewest flips to boost your performance. Sharpen your strategy to minimize your moves and maximize efficiency."
  },
  {
    title: "Embrace the Competition",
    description: "Keep an eye on the leaderboard! We keep detailed statistics so you can track your performance, see how you stack up against the competition, and improve your ranking."
  },
  {
    title: "Achieve Mastery",
    description: "The player who solves the puzzle with the fewest moves each day achieves mastery. Strive for excellence and perfect your puzzle-solving strategy!"
  },
  {
    title: "Climb the Leaderboard",
    description: "Every game is an opportunity to not only enjoy the challenge but also to climb up the leaderboard. Show off your puzzle prowess and reach the top!"
  }
];

export default contentData;
