import React, { useState, useEffect } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faSave, faTimes } from '@fortawesome/free-solid-svg-icons';
import { ToastContainer, toast } from 'react-toastify';
import { useUsername } from '../contexts/UsernameContext'; // Import the context hook
import 'react-toastify/dist/ReactToastify.css';

export const Username = () => {
    const { user, getAccessTokenSilently } = useAuth0();
    const { username, setUsername } = useUsername(); // Use context to manage username state
    const [loading, setLoading] = useState(true);
    const [editMode, setEditMode] = useState(false);

    useEffect(() => {
        const fetchUsername = async () => {
            if (!user) return; // Ensure there is a user context available

            try {
                setLoading(true);
                const token = await getAccessTokenSilently();
                const apiUrl = `${process.env.REACT_APP_API_ROOT_URL}:${process.env.REACT_APP_API_PORT}/api/username`;
                const response = await fetch(apiUrl, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });

                if (response.status === 200) {
                    const data = await response.json();
                    setUsername(data.username); // Set username in the context
                } else {
                    toast.error('Failed to fetch username.');
                }
            } catch (error) {
                console.error('Error fetching username:', error);
                toast.error('Error fetching username.');
            } finally {
                setLoading(false);
            }
        };

        fetchUsername();
    }, [user, getAccessTokenSilently, setUsername]); // Include setUsername in the dependency array

    const updateUsername = async (newUsername) => {
        if (!newUsername.trim()) {
            toast.error("Username cannot be blank.", {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return;
        }

        const token = await getAccessTokenSilently();
        const apiUrl = `${process.env.REACT_APP_API_ROOT_URL}:${process.env.REACT_APP_API_PORT}/api/username`;
        const response = await fetch(apiUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ username: newUsername }),
        });

        if (response.status === 200) {
            toast.success('Username updated!');
            setEditMode(false);
        } else {
            toast.error('Failed to update username. It might already be taken.');
        }
    };

    return (
        <div style={{ padding: '10px', fontSize: '16px' }}>
            <ToastContainer />
            {loading ? (
                <div>Loading...</div>
            ) : (
                username || editMode ? (
                    editMode ? (
                        <div>
                            <input
                                type="text"
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                                style={{ marginRight: '5px' }}
                            />
                            <button onClick={() => updateUsername(username)} style={{ border: 'none', background: 'none' }}>
                                <FontAwesomeIcon icon={faSave} title="Save" />
                            </button>
                            <button onClick={() => setEditMode(false)} style={{ border: 'none', background: 'none' }}>
                                <FontAwesomeIcon icon={faTimes} title="Cancel" />
                            </button>
                        </div>
                    ) : (
                        <div>
                            Welcome {username}! <button onClick={() => setEditMode(true)} style={{ border: 'none', background: 'none' }}>
                                <FontAwesomeIcon icon={faEdit} title="Edit Username" />
                            </button>
                        </div>
                    )
                ) : (
                    <div>No username set. <button onClick={() => setEditMode(true)}>Set Username</button></div>
                )
            )}
        </div>
    );
};

export default Username;
