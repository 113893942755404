import React, { useState } from "react";
import { Container, Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Highlight from "../components/Highlight";
import Loading from "../components/Loading";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { Card, CardBody, CardTitle, CardText } from 'reactstrap';

export const ProfileComponent = () => {
  const { user, logout } = useAuth0();
  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  const handleDeleteAccount = async () => {
    // Call API to delete user data and Auth0 account
    // Example: await fetch('/api/delete_user', { method: 'POST', body: JSON.stringify({ user_id: user.sub }) });
    // After deletion, log the user out
    logout({ returnTo: window.location.origin });
  };

  return (
    <Container className="mb-5">
      <Row className="align-items-center profile-header mb-5 text-center text-md-left">
        <Col md={2}>
          <img src={user.picture} alt="Profile" className="rounded-circle img-fluid profile-picture mb-3 mb-md-0" />
        </Col>
        <Col md>
          <h2>{user.name}</h2>
          <p className="lead text-muted">{user.email}</p>
        </Col>
      </Row>
      <Card>
        <CardBody>
          <CardTitle tag="h5">User Details</CardTitle>
          <CardText>Name: {user.name}</CardText>
          <CardText>Email: {user.email}</CardText>
          <CardText>Nickname: {user.nickname}</CardText>
          <CardText>Last Login: {new Date(user.updated_at).toLocaleString()}</CardText>
          <Button color="danger" onClick={toggle}>Delete Account</Button>
        </CardBody>
      </Card>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Confirm Account Deletion</ModalHeader>
        <ModalBody>
          Are you sure you want to delete your account? This action cannot be undone.
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={handleDeleteAccount}>Delete</Button>{' '}
          <Button color="secondary" onClick={toggle}>Cancel</Button>
        </ModalFooter>
      </Modal>
    </Container>
  );
};

export default withAuthenticationRequired(ProfileComponent, {
  onRedirecting: () => <Loading />,
});
